/* global axios */
import store from '@/store';

/* global axios */
export default class ServiceService {
  constructor() {
    this.isLoading = false;
    this.axios = axios;
  }

  async fetchAllServices() {
    return this.axios.get(`service`).
      then((response) => {
        return response.data.filterServices;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
