/* global axios */
export default class MediaChannelService {
  constructor() {
    this.axios = axios;
  }

  async index() {
    return this.axios.get(`/service/media_channels/all`).
      then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      })
  }
}
