<template>
	<div class="m-subheader">
		<div class="d-flex align-items-center">
			<div class="mr-auto">
				<h3 class="m-subheader__title m-subheader__title--separator">Campanhas</h3>
				<ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
					<li class="m-nav__item m-nav__item--home">
						<router-link :to="{name: 'service-campaigns'}" class="m-nav__link m-nav__link--icon">
							<i class="m-nav__link-icon la la-home"></i>
						</router-link>
					</li>
					<li class="m-nav__separator">-</li>
					<li class="m-nav__item">
						<span class="m-nav__link-text">Listagem de Campanhas de todos os serviços</span>
					</li>
				</ul>
			</div>
			<!-- <div>
					<button
						class="btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
						type="button"
						data-toggle="modal"
						data-target="#service-store"
					>
						<i class="fa fa-plus"></i>
					</button>
			</div>-->
		</div>
	</div>
</template>
<script>
	export default {
		name: "service-campaigns-header"
	};
</script>

<style>
</style>

