<template>
	<div v-if="$authorization.can('campaign-list-all')">
		<Header />
		<Body>
			<List />
		</Body>
	</div>
</template>

<script>
	import Header from "./components/CampaignHeader";
	import Body from "./components/CampaignBody";
	import List from "./components/CampaignList";

	export default {
		name: "service-campaigns-all",

		components: {
			List,
			Header,
			Body
		}
	};
</script>

<style>
</style>
