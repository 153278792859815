<template>
	<div class="m-content">
		<div class="row">
			<div class="col-12">
				<div class="m-portlet m-portlet--full-height">
					<div class="m-portlet__body">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "service-campaigns-body"
	};
</script>

<style>
</style>

