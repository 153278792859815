<template>
	<div>
		<div class="form-group m-form__group border-bottom pb-4 mb-5">
			<div class="row" v-if="isLoading">
				<div class="col-12 text-center">
					<div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
					<span class="ml-2 pt-5">Carregando filtros...</span>
				</div>
			</div>

			<section class="row" v-if="!isLoading">
				<div class="col-lg-4 form-group">
					<v-select
						placeholder="Serviço"
						:value="filter.service"
						:options="services"
						:onChange="(option) => filterService(option, 'service')"
					>
						<span slot="no-options">Nenhum resultado.</span>
					</v-select>
				</div>

				<div class="col-lg-4 form-group">
					<v-select
						placeholder="Ofertas"
						:value="filter.offer"
						:options="optionOffers"
						:onChange="(option) => apply(option, 'offer')"
						title="Escolha um Serviço"
						v-bind:class="{ disabled: disabledOffer }"
					>
						<span slot="no-options">Nenhum resultado.</span>
					</v-select>
				</div>

				<div class="col-lg-4 form-group">
					<v-select
						placeholder="Canal"
						:value="filter.channel"
						:options="optionChannels"
						:onChange="(option) => apply(option, 'channel')"
					>
						<span slot="no-options">Nenhum resultado.</span>
					</v-select>
				</div>

				<div class="col-lg-4 form-group">
					<v-select
						placeholder="Provedor de Mídia"
						:value="filter.providerMidia"
						:options="optionProvidersMidia"
						:onChange="(option) => apply(option, 'providerMidia')"
					>
						<span slot="no-options">Nenhum resultado.</span>
					</v-select>
				</div>

				<div class="col-lg-4 form-group">
					<v-select
						placeholder="Provedor de Conteúdo"
						:value="filter.providerContent"
						:options="optionProvidersContent"
						:onChange="(option) => apply(option, 'providerContent')"
					>
						<span slot="no-options">Nenhum resultado.</span>
					</v-select>
				</div>

				<div class="col-lg-4 form-group">
					<v-select
						placeholder="Tipo de Campanha"
						:value="filter.type"
						:options="optionTypes"
						:onChange="(option) => apply(option, 'type')"
					>
						<span slot="no-options">Nenhum resultado.</span>
					</v-select>
				</div>

				<!-- <div class="col-lg-4 form-group">
					<v-select
						placeholder="Status"
						:value="filter.status"
						:options="status"
						:onChange="(option) => apply(option, 'status')"
					>
						<span slot="no-options">Nenhum resultado.</span>
					</v-select>
				</div>-->

				<div class="col-lg-2 form-group mt-1" v-if="hasCampaigns">
					<button v-if="!downloading" class="btn m-btn btn-success" type="button" @click="exportCSV">
						<span>
							<i class="fa fa-file-export"></i>
							<span class="pl-2">CSV</span>
						</span>
					</button>

					<button
						v-if="downloading"
						class="btn m-btn btn-success m-loader m-loader--light m-loader--left"
						type="button"
					>
						<span>
							<span class="pl-2">CSV</span>
						</span>
					</button>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	import VSelect from "vue-select";
	import ServiceService from "../../services/ServiceService";
	import CampaignService from "../../services/CampaignService";
	import MediaChannelService from "../../services/MediaChannelService";
	import OfferService from "../../services/OfferService";
	import CompanyService from "../../services/CompanyService";

	export default {
		props: {
			hasCampaigns: {
				type: Boolean,
				required: true
			}
		},

		name: "service-campaigns-options",

		components: {
			VSelect
		},

		data() {
			return {
				isLoading: true,
				downloading: false,
				disabledOffer: true,
				offers: [],
				services: [],
				channels: [],
				types: [],
				providers: [],
				providersContent: [],
				status: [
					{
						label: "Ativo",
						value: "active"
					},
					{
						label: "Inativo",
						value: "inactive"
					}
				],
				companyService: new CompanyService(),
				serviceService: new ServiceService(),
				campaignService: new CampaignService(),
				offerService: new OfferService(),
				mediaChannelService: new MediaChannelService(),
				filter: {
					service: null,
					status: null,
					type: null,
					offer: null,
					channel: null,
					providerMidia: null,
					providerContent: null
				}
			};
		},

		computed: {
			optionTypes() {
				return this.formatOptions(this.types);
			},

			optionOffers() {
				return this.formatOptions(this.offers);
			},

			optionChannels() {
				return this.formatOptions(this.channels);
			},

			optionProvidersMidia() {
				return this.formatOptions(this.providersMidias);
			},

			optionProvidersContent() {
				return this.formatOptions(this.providersContent);
			}
		},

		async mounted() {
			this.services = await this.serviceService.fetchAllServices();
			this.types = await this.campaignService.getTypes();
			this.channels = await this.mediaChannelService.index();
			this.providersMidias = await this.companyService.medias();
			this.providersContent = await this.companyService.all();

			this.isLoading = false;
		},

		methods: {
			apply(option, label) {
				this.filter[label] = option;
				this.$emit("filter", this.filter);
			},

			async filterService(option, label) {
				this.apply(option, label);

				this.filter.offer = null;

				this.disabledOffer = true;

				if (option.value != null) {
					this.offerService.offers(option.value).then(response => {
						this.offers = response.data;

						if (this.offers.length) {
							this.disabledOffer = false;
						}
					});
				}
			},

			formatOptions(array) {
				const options = [];

				if (typeof array != "undefined" && array.length) {
					array.forEach(element => {
						options.push(
							new Object({
								label: element.name,
								value: element.id
							})
						);
					});
				}

				return options;
			},

			exportCSV() {
				this.downloading = true;

				axios({
					url: `/service/campaign/csv`,
					method: "GET",
					responseType: "blob",
					params: {
						filters: this.filter
					}
				})
					.then(response => {
						const url = window.URL.createObjectURL(
							new Blob([response.data])
						);

						const link = document.createElement("a");
						link.href = url;
						let name = "campanhas.csv";
						link.setAttribute("download", name);
						document.body.appendChild(link);
						this.downloading = false;
						link.click();
					})
					.catch(error => {
						this.downloading = false;
						console.log(error);
					});
			}
		}
	};
</script>

<style>
</style>
