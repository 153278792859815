<template>
	<div>
		<Options @filter="filter($event)" :hasCampaigns="paginate.data.length > 0" />

		<div class="table-responsive">
			<table class="table m-table table-hover m-table--head-separator-primary">
				<thead>
					<tr>
						<th nowrap v-for="(header, index) in headers" :key="index">{{ header }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="isLoading">
						<td style="text-align: center;" colspan="12">
							<div
								v-if="isLoading"
								class="m-loader m-loader--danger"
								style="width: 30px; display: inline-block;"
							></div>
						</td>
					</tr>
					<template v-if="!isLoading">
						<tr v-for="(campaign, index) in paginate.data" :key="index">
							<td nowrap="nowrap">{{ campaign.service == null ? '-' : campaign.service.name }}</td>
							<td nowrap="nowrap">{{ campaign.media_channel.name }}</td>
							<td nowrap>{{ campaign.type.name }}</td>
							<td nowrap>{{ campaign.campaign_id }}</td>
							<td nowrap>{{ campaign.keyword || '-' }}</td>
							<td nowrap>{{ campaign.offer.name }}</td>
							<td nowrap>{{ campaign.offer.key }}</td>
							<td nowrap="nowrap">{{ campaign.company.name }}</td>
							<td nowrap="nowrap">{{ campaign.name == null ? '-' : campaign.name }}</td>
							<td nowrap="nowrap">{{ companyContent(campaign) }}</td>
							<!-- <td nowrap>{{ campaign.status == "active" ? "Ativo" : "Inativo" }}</td> -->
						</tr>
					</template>
				</tbody>
			</table>
		</div>

		<div class="row mt-3">
			<div
				class="col-6 mt-3"
			>Mostrando de {{ this.paginate.from }} até {{ this.paginate.to }} de {{ this.paginate.total }} registros</div>
			<div class="col-md-6 pull-right">
				<b-pagination
					size="md"
					align="right"
					:total-rows="paginate.total"
					:per-page="paginate.per_page"
					v-model="paginate.current_page"
					@change="changePage"
				></b-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import CampaignService from "../../services/CampaignService";
	import Options from "./CampaignOptions";

	export default {
		name: "service-campaigns-list",

		components: {
			Options
		},

		data() {
			return {
				isLoading: true,
				campaignService: new CampaignService(),
				page: 1,
				paginate: {
					data: []
				},
				filters: {},
				headers: [
					"Serviço",
					"Canal",
					"Tipo de Campanha",
					"ID da Campanha",
					"Keyword",
					"Oferta",
					"OfferKey",
					"Provedor de Mídia",
					"Nome",
					"Provedor de Conteúdo",
					// "Status"
				]
			};
		},

		async mounted() {
			await this.changePage();
		},

		methods: {
			async changePage(page) {
				this.isLoading = true;
				this.paginate = await this.campaignService.paginate(
					page,
					this.filters
				);
				this.isLoading = false;
			},

			async filter(filters) {
				this.isLoading = true;

				this.filters = filters;
				this.paginate = await this.campaignService.paginate(
					this.page,
					this.filters
				);

				this.isLoading = false;
			},

			companyContent(campaign){
				if (campaign.service != null) {
					if (campaign.service.company_content != null) {
						return campaign.service.company_content.name
					}
				}

				return '-';
			},

			keywords(keywords) {
				let response = [];

				keywords.find(keyword => {
					if (keyword.keywords) {
						response.push(keyword.keywords);
					}
				});

				return response.join(", ");
			}
		}
	};
</script>

<style>
	.table td {
		padding: 20px;
	}
</style>
