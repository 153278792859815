/* global axios */
export default class CompanyService {
  constructor() {
    this.axios = axios;
  }

  async all() {
    return this.axios.get(`/service/company/all`).
      then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      })
  }

  async medias() {
    return this.axios.get(`/service/company/medias`).
      then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      })
  }
}
